<template>
  <div class="container">
    <div class="search">
      <el-row>
        <el-col :span="6">
          <span>所属公司 : </span>
          <el-select
            v-model="company"
            placeholder="请选择"
            @change="getcorporation"
            v-if="flag"
          >
            <el-option
              v-for="item in companyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <span>所属项目 : </span>
          <el-select
            v-model="corporation"
            placeholder="请选择"
            v-if="flags"
            @change="getUser"
          >
            <el-option
              v-for="item in corporationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6"></el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <div class="main">
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="companys" label="所属公司"> </el-table-column>
        <el-table-column prop="corporations" label="所属项目">
        </el-table-column>
        <el-table-column prop="position" label="职位"> </el-table-column>
        <el-table-column prop="rank" label="权限"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="openAddUserDialog(scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
    </div>

    <el-dialog
      title="修改人员信息"
      :visible.sync="editUserDialogVisible"
      width="40%"
    >
      <el-form
        ref="editFormData"
        :model="editFormData"
        label-width="80px"
        :rules="editFormDataRules"
      >
        <el-form-item label="用户名" prop="name">
          <el-input v-model="editFormData.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="editFormData.password"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="editFormData.phone"></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户等级" prop="rank">
          <el-select v-model="editFormData.rank" placeholder="请选择">
            <el-option
              v-for="item in rankOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="职位" prop="position">
          <el-input v-model="editFormData.position"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  addUserInfo,
  getFirm,
  getProjectInfo,
  getUserInfo,
  editUserInfo,
  deleteUserInfo,
} from "../../../request/project";
export default {
  data() {
    return {
      company: "", //公司下拉框
      corporation: "", //项目下拉框
      editUserDialogVisible: false,

      editFormData: {
        name: "",
        password: "",
        phone: "",
        rank: "",
        position: "",
        corporation: "",
        company: "",
      },
      rankOptions: [
        {
          value: 0,
          label: "系统管理员",
        },
        {
          value: 1,
          label: "公司管理员",
        },
        {
          value: 2,
          label: "项目管理员",
        },
        {
          value: 3,
          label: "普通员工",
        },
      ],
      editFormDataRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入手机号", trigger: "change" }],
        position: [
          { required: true, message: "请输入职位", trigger: "change" },
        ],
        company: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
      companyOptions: [],
      corporationOptions: [],
      flag: false,
      flags: false,
      tableData: [],
      total:0,//分页总数
      page:1,
      pageSize:10,
    };
  },
  created() {
    // 获取所有公司
    // this.getcompany();
    this.getProject();
  },
  mounted() {},
  methods: {
    // 获取所有项目
    async getProject() {
      let rank = window.localStorage.getItem("rank");
      if (rank == 0) {
        // 超级管理员
        this.flag = true;
        this.flags = true;
        // 超级管理员获取所有公司和项目
        this.getcompany();
      }   else {
        let msg = {
           corporation:this.flag?  window.localStorage.getItem("companys") : window.localStorage.getItem("corporations"),
        corporation:this.flag? 1 : window.localStorage.getItem("corporations"),
          page:this.page,
          size:this.pageSize,
          name:"",
          types:"",

        };
        const res = await getUserInfo(msg);
        console.log(res);
        if (res.data.length == 0) {
          return;
        }
        this.tableData = res.data;
        for (let i = 0; i < this.tableData.length; i++) {
          if (this.tableData.rank == 0) {
            this.tableData.rank = "超级管理员";
          } else if (this.tableData.rank == 1) {
            this.tableData.rank = "公司管理员";
          } else if (this.tableData.rank == 2) {
            this.tableData.rank = "项目管理员";
          } else if (this.tableData.rank == 2) {
            this.tableData.rank = "普通用户";
          }
        }
      }
    },
    // 获取选中项目下的所有人员
    async getUser() {
      this.tableData = [];
      let msg = {
        // corporation: this.corporation,
        corporation: localStorage.getItem('corporations'),
      };
      const res = await getUserInfo(msg);
      console.log(res);
      if (res.data.length == 0) {
        return;
      }
      this.tableData = res.data;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].rank == 0) {
          this.tableData[i].rank = "超级管理员";
        } else if (this.tableData[i].rank == 1) {
          this.tableData[i].rank = "公司管理员";
        } else if (this.tableData[i].rank == 2) {
          this.tableData[i].rank = "项目管理员";
        } else if (this.tableData[i].rank == 3) {
          this.tableData[i].rank = "普通用户";
        }
      }
    },
    // 获取所有公司
    async getcompany() {
      const { data: res } = await getFirm();
      //  console.log(res);
      for (let i = 0; i < res.length; i++) {
        let obj = {};
        obj.value = res[i].id;
        obj.label = res[i].name;
        this.companyOptions.push(obj);
      }
      this.company = this.companyOptions[0].value;
      this.getcorporation();
      // console.log(this.companyOptions);
    },
    // 打开修改用户信息对话框
    openAddUserDialog(row) {
      this.editUserDialogVisible = true;
      this.editFormData = JSON.parse(JSON.stringify(row));
      this.editFormData.rank -= 0;
      //  this.editFormData= JSON.parse(JSON.stringify(this.editFormData).replace(/uid/g,"id"));
      if (localStorage.getItem("rank") == 0) {
        this.rankOptions = [
          {
          value: 0,
          label: "系统管理员",
        },
          {
            value: 1,
            label: "公司管理员",
          },
          {
            value: 2,
            label: "项目管理员",
          },
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else if(localStorage.getItem("rank") == 1) {
        this.rankOptions = [
           
          {
            value: 2,
            label: "项目管理员",
          },
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else if(localStorage.getItem("rank") == 2) {
          this.rankOptions = [
          {
            value: 3,
            label: "普通员工",
          },
        ];
      } else {
         this.rankOptions = [
           
        ];
      }
    },
    // 获取选中公司的项目
    async getcorporation() {
      this.tableData = [];
      this.corporationOptions = [];
      this.corporation = "";
      if (window.localStorage.getItem("rank") == 0) {
        // 如果是超级管理员,从选中的公司获取项目
        let msg = {
             corporation:this.flag? window.localStorage.getItem("corporations"): window.localStorage.getItem("companys"),
        pro_id:this.flag? 1 : 0,
          page:this.page,
          size:this.pageSize,
          name:"",
          types:"",
        };
        const { data: res } = await getProjectInfo(msg);
        if (res.length == 0) {
          return;
        }
        console.log(res);
        for (let i = 0; i < res.data.length; i++) {
          let obj = {};
          obj.value = res.data[i].id;
          obj.label = res.data[i].name;
          this.corporationOptions.push(obj);
        }

        this.corporation = this.corporationOptions[0].value;
        // console.log(res);
        this.getUser();
      } else if (window.localStorage.getItem("rank") == 1) {
        // 如果是公司管理员,从localstroge拿公司id获取项目
        let msg = {
          corporation: window.localStorage.getItem("companys"),
        };
        const { data: res } = await getProjectInfo(msg);
        if (res.length == 0) {
          return;
        }
        for (let i = 0; i < res.length; i++) {
          let obj = {};
          obj.value = res[i].id;
          obj.label = res[i].name;
          this.corporationOptions.push(obj);
        }

        this.corporation = this.corporationOptions[0].value;
        // console.log(res);
        this.getUser();
      }
    },
    // 修改用户信息
    async editUser() {
      const res = await editUserInfo(this.editFormData);
      if (res.data.code == 200) {
        this.editUserDialogVisible = false;
        this.getUser();
        this.$message({
          message: "修改成功",
          type: "success",
        });
        return;
      }
      this.$message({
        message: "修改失败",
        type: "error",
      });
    },
    // 删除用户
    deleteUser(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let msg = {
            id: row.uid,
          };
          const res = await deleteUserInfo(msg);
          console.log(res);
          if (res.data.code == 200) {
            this.editUserDialogVisible = false;
            this.getUser();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 一页显示几条改变 
    handleSizeChange(val){
      this.pageSize = val;
    },
     // 页码改变 
    handleCurrentChange(val){
      this.page = val;
    },
  },
};
</script>
<style>
.main {
  padding: 20px;
}
</style>